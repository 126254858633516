import React from "react"
import PageTransition from 'gatsby-plugin-page-transitions';
// import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import WhoAreWe from "../../components/who-are-we"
import Sponsores from "../../components/sponsores"
import Realisation from "../../components/realisation"
class Sodexo extends React.Component {
  render = () => (
    
        <Layout>
            <SEO title="Sodexo" keywords={[`gatsby`, `application`, `react`]} />
            <div className="hero hero--smallest hero--project-sodexo inuit-box inuit-box--flush">
                <h2 className="hero__message hero__message--bigger hero__message--center">
                
                </h2>
            </div>
            <article className="article project inuit-u-p0">
                <div className="article__body article__body--has-extra">

                    <aside className="article__extra">
                        <p className="article__para inuit-u-p0">
                            <a className="project__link" href="https://mysodexo.ma/" title="" target="_blank">
                                <img src="/img/clients/sodexoiconapp.png" alt="" className="project__logo" />
                            </a>
                        </p>

                        <p className="article__para inuit-u-p0">
                            <span className="project__detail">SODEXO PASS MAROC.</span>
                            <span className="project__detail project__detail--emphasis">
                                <a className="project__link" href="https://mysodexo.ma/" title="PRECISION SYSTEMS" target="_blank">mysodexo.ma</a>
                            </span>
                            <span className="project__detail">Year 2019</span>
                        </p>
                    </aside>

                    <p className="v-center">
                        <h1 className="article__heading m-top">MY SODEXO MAROC.</h1>
                        <p className="article__para">
                            Grâce à son module de géolocalisation, l’application mobile Sodexo permet de localiser rapidement sur une carte les commerces affiliés à proximité de l’utilisateur. 
                        </p>
                    </p>

                </div>


                <p className="article__belt" style={{ marginTop: "0" }}>
                    <img className="article__belt-image" src="/img/projects/mysodexo/banner-project.png" alt="mobile-apps" />
                </p>

                <div className="article__body">
                    <h2 className="article__heading">Le challenge</h2>
                    <p className="article__para">
                        Concevoir une application intuitive, avec un design ergonomique et moderne. <br />
                        Nous avons adopté une approche de conception mobile-first.Grâce à cette approche, le design s’adapte même au plus petit des appareils mobiles, puis généralisé à des écrans plus grand jusqu’à l’interface desktop.<br />
                    </p>
                </div>

                <p className="article__belt inuit-u-mb0">
                    <img className="article__belt-image" src="/img/projects/mysodexo/maquette1.png" alt="branding" />
                </p>

                <p className="article__belt inuit-u-mb0">
                    <img className="article__belt-image" src="/img/projects/mysodexo/uxinfoaffilie.png" alt="branding" />
                </p>
                <br /><br />
            </article>
            <Realisation/>

            <Sponsores />
            <WhoAreWe />
        </Layout>
    
  )

}

export default Sodexo
